import { TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

const Title = ({ id, item, editMode, setData }) => {
  const [value, setValue] = useState(item?.data?.title || '');

  return (
    <>
      {editMode ? (
        <TextField
          onBlurCapture={(e) => {
            setData(id, 'title', e.target.value);
          }}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          fullWidth
          onBlur={(e) => {
            setData(id, 'title', e.target.value);
          }}
        />
      ) : (
        <Typography variant='h4' textAlign={'center'}>
          {item.data.title}
        </Typography>
      )}
    </>
  );
};

export default Title;
