import React from 'react';
import useGetTrendingCollections from '../../../hooks/useGetTrendingCollections';
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { ChainIcon } from '../../Icon';
import { getTokenName } from '../../../helpers/getChainName';
import { LoadingSpinner } from '../../loadingSpinner';
import { ImageFixedAO } from '../../Image_fixed_aspect_ratio';
import { normalizeNumber } from '../../../helpers/digits';
import { getCdnUrl } from '../../../lib/cdnUrl';

const TrendingCollection = ({ options, data }) => {
  const trendingCollections = useGetTrendingCollections();
  const { data: collections, isLoading } = trendingCollections;
  const columns = [
    { id: 'collection', label: 'Collection', minWidth: 50 },
    { id: 'volume', label: 'Volume', minWidth: 50 },
  ];

  return (
    <>
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={column.id}
              align={column.align}
              colSpan={column.colspan}
              style={{ minWidth: column.minWidth }}
              classes={{
                root: 'dark:bg-jacarta-600 bg-light-base dark:text-jacarta-100 text-jacarta-700',
              }}
            >
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {isLoading && (
          <TableRow>
            <TableCell align='center' rowSpan={5} colSpan={3}>
              <LoadingSpinner className='flex justify-center' />
            </TableCell>
          </TableRow>
        )}
        {!isLoading &&
          collections?.map((row, index) => {
            return (
              <TableRow key={index}>
                <TableCell
                  classes={{
                    root: 'flex items-center dark:bg-jacarta-600 dark:text-jacarta-100 text-jacarta-700',
                  }}
                >
                  <figure className='relative mr-2 w-8 shrink-0 self-start lg:mr-5 lg:w-12'>
                    <ImageFixedAO
                      image={getCdnUrl(row?.collection?.profilePhotoPath)}
                      alt={row?.collection?.name}
                      bottomRounded={true}
                    />
                  </figure>
                  <span className='font-display text-jacarta-700 text-sm font-semibold dark:text-white'>
                    {row?.collection?.name}
                  </span>
                </TableCell>
                <TableCell
                  classes={{ root: 'dark:bg-jacarta-600 dark:text-jacarta-100 text-jacarta-700' }}
                >
                  <span className='dark:border-jacarta-600 border-jacarta-100 flex items-center whitespace-nowrap rounded-md py-1'>
                    <ChainIcon
                      name={row?.collection?.chain}
                      tooltip={getTokenName(row?.collection?.chain)}
                      sideGap={true}
                    />

                    <span className='text text-sm font-medium tracking-tight'>
                      {normalizeNumber(row?.volume)}
                    </span>
                  </span>
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </>
  );
};

export default TrendingCollection;
