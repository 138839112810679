import { CryptoCurrencyMarket, TechnicalAnalysis, Ticker } from 'react-ts-tradingview-widgets';
import { useTheme } from 'next-themes';
import pairs from './tv/pairs';
import { useEffect, useState } from 'react';
import { Autocomplete, Grid, TextField } from '@mui/material';

const TechnicalAnalysisWidget = ({ editMode, id, item, setData }) => {
  const { theme, setTheme } = useTheme();

  const [symbol, setSymbol] = useState(item?.data?.symbol || 'BNBUSDT');

  const [allSymbols, setAllSymbols] = useState([]);

  const [isSelected, setIsSelected] = useState(false);
  const [range, setRange] = useState(item?.data?.range || '1m');

  useEffect(() => {
    let symbols = pairs.map((pair) => {
      pair.label = pair.name;
      return pair;
    });

    setAllSymbols(symbols);

    return () => {};
  }, []);

  return (
    <div>
      {editMode ? (
        <Grid container>
          <Autocomplete
            disabled={!editMode}
            options={allSymbols}
            value={symbol}
            // getOptionSelected={(option, value) => value.proName === option.proName}
            onChange={(e, newValue) => {
              setSymbol(newValue);
              setIsSelected(true);
              setData(id, 'symbol', newValue);
            }}
            sx={{
              width: 200,
            }}
            className={
              'h-12 rounded-lg border border-jacarta-100 bg-jacarta-50 px-4 text-sm  text-jacarta-700 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:text-white'
            }
            renderInput={(params) => {
              params.InputProps.className +=
                ' !bg-transparent !p-0 !m-0 !h-full text-jacarta-700 dark:text-white';
              params.inputProps.className +=
                ' !bg-transparent !m-0 !p-3 !h-full text-jacarta-700 dark:text-white';
              // params.InputLabelProps.style = { display: "hidden"}

              return (
                <TextField
                  {...params}
                  className={'!bg-transparent !text-red'}
                  label={
                    isSelected
                      ? undefined
                      : item?.data?.symbol
                      ? item?.data?.symbol?.name
                      : 'BNBUSDT'
                  }
                  InputLabelProps={{
                    sx: {
                      color: 'black',
                    },
                  }}
                />
              );
            }}
          />

          <select
            disabled={!editMode}
            className='ml-2 h-12 w-[150px] rounded-lg border border-jacarta-100 bg-jacarta-50 px-4 text-sm  text-jacarta-700 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white'
            value={range}
            onChange={(e) => {
              setRange(e.target.value);
              setData(id, 'range', e.target.value);
            }}
          >
            <option value={'1m'}>1 Minute</option>
            <option value={'5m'}>5 Minutes</option>
            <option value={'15m'}>15 Minutes</option>
            <option value={'30m'}>30 Minutes</option>
            <option value={'1h'}>1 Hour</option>
            <option value={'4h'}>4 Hours</option>
            <option value={'1D'}>1 Day</option>
            <option value={'1W'}>1 Week</option>
            <option value={'1M'}>1 Month</option>
          </select>
        </Grid>
      ) : (
        <div className='h-[20px]' />
      )}
      <div className='mt-2'>
        <TechnicalAnalysis
          interval={range}
          colorTheme={theme}
          className='h-full w-full'
          symbol={item?.data?.symbol ? item?.data?.symbol?.symbol : 'BNBUSDT'}
          width='100%'
        />
      </div>
    </div>
  );
};

export default TechnicalAnalysisWidget;
