import React, { useEffect } from 'react';
import Meta from '../../components/Meta';
import { HiPlus } from 'react-icons/hi';
import { ReactGridLayout } from '../../components/ReactGridLayout';
import { FormControlLabel, Switch } from '@mui/material';
import { useRouter } from 'next/router';
import { useAccount, useSignTypedData, useNetwork } from 'wagmi';
import { useGetWidget } from '../../hooks/useGetWidget';
import { getAddress, hashMessage } from 'ethers/lib/utils';
import saveLayout from '../../services/saveLayout';
import { useGetLayout } from '../../hooks/useGetLayout';
import UserSection, { UserSectionSlim } from '../../components/user/UserSection';
import { CheckInput } from '../../components/checkInput';
import useGetUser from '../../hooks/useGetUser';
import editUserProfile, { updateWidgetAsHome } from '../../services/editUserProfile';
import { dynamicDomain, layoutMessage, types } from '../../lib/sign';

function MyHome({ noSpaceMobile }) {
  let {
    query: { user },
  } = useRouter();
  const { address: walletAddress } = useAccount();

  if (!user) user = walletAddress;

  const [editMode, setEditMode] = React.useState(false);
  const { data: userLayout } = useGetLayout(user);
  const [layout, setLayout] = React.useState([]);
  const { data: leftMenuItems } = useGetWidget();
  const { data: userObject } = useGetUser(walletAddress);
  const [showWidgetsAsHome, setShowWidgetsAsHome] = React.useState(false);
  const { chain } = useNetwork();

  useEffect(() => {
    if (userObject?.showWidgetsAsHome !== undefined) {
      setShowWidgetsAsHome(userObject?.showWidgetsAsHome);
    }
  }, [userObject]);

  useEffect(() => {
    if (userLayout) {
      setLayout(userLayout?.layout);
    }
  }, [userLayout]);
  const addItem = (result) => {
    if (result?.component) {
      const maxY = Math.max(...layout.map((l) => l.y));
      const _layout = [
        ...layout,
        {
          i: `${result.id}_${Date.now()}`,
          ...result,
          x: 0,
          y: maxY + 100,
        },
      ];
      setLayout(_layout);
    }
  };
  const { signTypedDataAsync } = useSignTypedData({
    domain: dynamicDomain(chain?.hexId),
    types: types,
    primaryType: 'Layout',
  });
  const save = async () => {
    const inputs = JSON.stringify({
      layout,
      walletAddress,
    });
    await SaveShowWidgetsAsHome();
    //   const hashedMessage = hashMessage(inputs);
    const signature = await signTypedDataAsync({ message: { Layout: layoutMessage } });
    const response = await saveLayout(inputs, signature, chain?.hexId);
    if (response?.status === 'success') {
      setEditMode(false);
    }
  };

  const cancel = () => {
    if (userLayout) {
      setLayout(userLayout?.layout);
    }
    setEditMode(false);
  };

  useEffect(() => {
    if (!walletAddress) {
      if (userLayout) {
        setLayout(userLayout?.layout);
      }
      setEditMode(false);
    }
  }, [walletAddress]);

  const SaveShowWidgetsAsHome = async () => {
    const inputs = JSON.stringify({
      showWidgetsAsHome: showWidgetsAsHome,
      walletAddress: getAddress(walletAddress),
    });
    await updateWidgetAsHome(inputs);
  };

  return (
    <div className={`${noSpaceMobile ? 'md: pt-0' : ''} `}>
      <Meta title='My Home || Bit5 | Create. Collect. Connect.' />
      <UserSectionSlim />
      <div className='w-full bg-[#8358ff12] pt-5 xl:px-24'>
        {walletAddress === user &&
          (editMode ? (
            <div className='mb-5 flex w-full md:justify-between'>
              <div className='ml-2 w-fit'>
                <CheckInput
                  label={'Use as Home Page'}
                  checked={showWidgetsAsHome}
                  onChange={(val) => setShowWidgetsAsHome(val)}
                />
              </div>
              <div className='ml-auto w-[130px] text-right md:w-fit'>
                <button
                  onClick={save}
                  className='rounded-xl mr-2 bg-accent py-3 px-8 text-center font-semibold text-white transition-all hover:bg-accent-dark'
                >
                  Save
                </button>
                <button
                  onClick={cancel}
                  className='rounded-xl mt-2 mr-2 bg-red py-3 px-8 text-center font-semibold text-white opacity-90 transition-all hover:opacity-100 md:mt-0'
                >
                  Cancel
                </button>
              </div>
            </div>
          ) : (
            <div className='mb-5 flex w-full justify-between'>
              <div />
              <button
                onClick={(e) => setEditMode(true)}
                className='rounded-xl mr-2 bg-accent py-3 px-8 text-center font-semibold text-white transition-all hover:bg-accent-dark'
              >
                Edit
              </button>
            </div>
          ))}

        <div className='flex flex-col space-x-5 px-2 md:flex-row'>
          {editMode && (
            <div
              className='scrollbar-custom w-full overflow-y-auto rounded-lg border border-jacarta-100 dark:border-jacarta-600 md:sticky md:top-[var(--header-height)] md:w-1/6'
              style={{
                maxHeight: 'calc(100vh - 200px)',
              }}
            >
              <div className='flex flex-col space-y-5 p-5'>
                <div className='flex flex-col space-y-3'>
                  {leftMenuItems &&
                    leftMenuItems.map((item, index) => (
                      <LeftMenuItem key={item.id} item={item} addItem={addItem} />
                    ))}
                </div>
              </div>
            </div>
          )}
          {layout?.length > 0 ? (
            <ReactGridLayout
              editMode={editMode}
              layout={layout}
              setLayout={setLayout}
              leftMenuItems={leftMenuItems || []}
            />
          ) : (
            <h3 className='flex h-[50vh] w-full items-center justify-center text-3xl'>
              Create Your Dashboard
            </h3>
          )}
        </div>
      </div>
    </div>
  );
}

export default MyHome;

const LeftMenuItem = ({ item, addItem }) => {
  return (
    <div
      className='flex w-full cursor-pointer select-none items-center justify-between rounded-lg border border-jacarta-100 py-3 px-3'
      onClick={(e) => addItem(item)}
    >
      <p className='text-sm font-medium text-jacarta-500 dark:text-white'>{item.name}</p>
      <HiPlus />
    </div>
  );
};
