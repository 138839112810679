import React, { useEffect } from 'react';
import GridLayout from 'react-grid-layout';
import { AiFillCloseCircle } from 'react-icons/ai';
import TradingViewWidget from '../Widgets/TradingViewWidget';
import FearAndGreed from '../Widgets/FearAndGreed';
import TwitterWidget from '../Widgets/TwitterWidget';
import TableWidget from '../Widgets/Table/TableWidget';
import TickerWidget from '../Widgets/TickerWidget';
import HeatMap from '../Widgets/HeatMap';
import BubbleMaps from '../Widgets/BubbleMaps';
import useWindowSize from '../../helpers/useWindowSize';
import Title from '../Widgets/Title';
import TimelineWidget from '../Widgets/TimelineWidget';
import CryptocurrencyMarketWidget from '../Widgets/CryptocurrencyMarketWidget';
import TechnicalAnalysisWidget from '../Widgets/TechnicalAnalysisWidget';

const extractSizeProperties = (layout) => {
  const { w, h, x, y, i } = layout;
  return { w, h, x, y, i };
};

export const ReactGridLayout = ({ leftMenuItems, layout, setLayout, editMode, ...props }) => {
  const ref = React.useRef();
  useWindowSize();
  const [refresh, setRefresh] = React.useState(1);
  useEffect(() => {
    setRefresh(refresh + 1);
  }, [editMode, leftMenuItems]);

  const onChangeLayout = (_layout) => {
    const _l = _layout.map((l) => {
      const item = leftMenuItems.find((i) => i.id === String(l.i).split('_')[0]);
      return {
        ...item,
        ...extractSizeProperties(l),
      };
    });
    setLayout(_l);
  };

  const deleteItem = (id) => {
    const _layout = layout.filter((l) => l.i !== id);
    setLayout(_layout);
  };

  const setData = (id, name, data) => {
    const _layout = layout.map((l) => {
      if (l.i === id) {
        return {
          ...l,
          data: { ...l?.data, [name]: data },
        };
      }
      return l;
    });
    setLayout(_layout);
  };

  const setDataX = (id, name, data) => {
    const _layout = layout.map((l, i) => {
      if (i === id) {
        return {
          ...l,
          data: { ...l?.data, [name]: data },
        };
      }
      return l;
    });
    setLayout(_layout);
  };

  return (
    <div
      className={
        (editMode ? 'border md:w-5/6' : 'w-full') +
        ` mt-5 select-none rounded-lg border-jacarta-100 md:mt-0`
      }
      ref={ref}
    >
      <GridLayout
        isResizable={editMode}
        isDraggable={editMode}
        className='layout w-full !p-0'
        onLayoutChange={onChangeLayout}
        layout={layout}
        cols={12}
        rowHeight={5}
        onResizeStop={(layout, oldItem, newItem, placeholder, e, element) => {
          const customEvent = new CustomEvent('resizeStop', {
            detail: {
              element,
            },
          });
          window.dispatchEvent(customEvent);
        }}
        width={ref?.current?.offsetWidth}
        {...props}
      >
        {layout.map((item, index) => (
          <div
            key={item.i}
            className='overflow-hidden !border-none !shadow-md dark:!bg-jacarta-600'
          >
            <span className='ml-3 mb-2 block font-display'>{item.name}</span>
            <div className='h-full w-full px-2 pb-7'>
              {editMode && (
                <AiFillCloseCircle
                  className='react-options-handle absolute right-0 top-0 z-10 cursor-pointer text-jacarta-400'
                  onClick={() => {
                    deleteItem(item.i);
                  }}
                />
              )}
              {item?.component === 'twitter' && (
                <TwitterWidget
                  id={item.i}
                  data={item.data}
                  options={item?.options}
                  editMode={editMode}
                  setData={setData}
                />
              )}
              {item?.component === 'feargreed' && <FearAndGreed />}

              {item?.component === 'chart' && (
                <TradingViewWidget id={index} item={item} editMode={editMode} setData={setDataX} />
              )}

              {item?.component === 'timeline' && (
                <TimelineWidget id={index} item={item} editMode={editMode} setData={setDataX} />
              )}

              {item?.component === 'crypto-market' && (
                <CryptocurrencyMarketWidget
                  id={index}
                  item={item}
                  editMode={editMode}
                  setData={setDataX}
                />
              )}

              {item?.component === 'table' && (
                <TableWidget
                  id={item.i}
                  data={item.data}
                  options={item?.options}
                  editMode={editMode}
                  setData={setData}
                />
              )}
              {item?.component === 'ticker' && (
                <TickerWidget id={index} item={item} editMode={editMode} setData={setDataX} />
              )}
              {item?.component === 'technical-analysis' && (
                <TechnicalAnalysisWidget
                  id={index}
                  item={item}
                  editMode={editMode}
                  setData={setDataX}
                />
              )}
              {item?.component === 'heatmap' && <HeatMap item={item} />}
              {item?.component === 'bubblemaps' && <BubbleMaps />}
              {item?.component === 'title' && (
                <Title id={index} item={item} editMode={editMode} setData={setDataX} />
              )}
            </div>
          </div>
        ))}
      </GridLayout>
    </div>
  );
};
