import { constants } from '../helpers/constants';
import axios from '../lib/axios';

export default async function saveLayout(data, signature, chainId) {
  const config = {
    url: `${constants.api.url_new}/layout`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: constants.httpMethods.post,
    data: { data: JSON.parse(data), signature, chainId },
  };

  const res = (await axios(config)).data;

  return res;
}
