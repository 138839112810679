import React, { useEffect, useState } from 'react';

const HeatMap = ({ item, editMode }) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    window.addEventListener('resizeStop', (event) => {
      setWidth(event.detail?.element?.parentElement.getBoundingClientRect().width);
      setHeight(event.detail?.element?.parentElement.getBoundingClientRect().height);
    });
  }, []);
  useEffect(() => {
    window.addEventListener('resizeStop', (event) => {
      setWidth(event.detail?.element?.parentElement.getBoundingClientRect().width);
      setHeight(event.detail?.element?.parentElement.getBoundingClientRect().height);
    });

    const script = document.createElement('script');
    script.src = 'https://widgets.coingecko.com/coingecko-coin-heatmap-widget.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <coingecko-coin-heatmap-widget
      height={height || item.h * 14}
      width={width || item.w * 85}
      locale='en'
    />
  );
};

export default HeatMap;
