import React, { useEffect, useState } from 'react';
import { useGetWidgetFilter } from '../../../hooks/useGetWidget';
import { Autocomplete, TextField } from '@mui/material';
import { shortenAddress } from '../../../helpers/address';

const OptionInput = ({ id, option: { name, type }, disabled, data, setData }) => {
  const [initialValue, setInitialValue] = useState(null);
  const [value, setValue] = useState('');
  const [autocomplete, setAutocomplete] = useState(null);
  const datas = useGetWidgetFilter(name, autocomplete)?.data;

  useEffect(() => {
    setData(id, name, value);
  }, [value]);

  useEffect(() => {
    const inital = data[name];
    if (inital) {
      setInitialValue(inital);
    }
  }, [data, datas]);
  if (type === 'dropdown') {
    return (
      <div className='group flex'>
        <Autocomplete
          disabled={disabled}
          options={
            datas
              ? datas.map((data) => {
                  return {
                    id: data.value,
                    label: data.name,
                  };
                })
              : []
          }
          value={initialValue || value}
          onChange={(event, newValue) => {
            setValue(newValue?.id);
          }}
          clearText='Clear'
          className={disabled ? '' : 'dark:invert'}
          sx={{ width: 300 }}
          disableClearable
          renderInput={(params) =>
            disabled ? (
              <div className='capitalize dark:!text-white'>
                {initialValue ? `${name}: ${shortenAddress(initialValue)}` : `${name}: All`}
              </div>
            ) : (
              <TextField
                className='rounded-md  capitalize '
                {...params}
                value={autocomplete}
                label={name}
                onChange={(e) => {
                  setAutocomplete(e.target.value);
                }}
              />
            )
          }
        />

        <button
          onClick={() => {
            setValue(null);
            setAutocomplete('');
            setInitialValue(null);
          }}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            className='w-[12px] opacity-0 group-hover:opacity-100'
          >
            <path
              d='M.44,21.44a1.49,1.49,0,0,0,0,2.12,1.5,1.5,0,0,0,2.12,0l9.26-9.26a.25.25,0,0,1,.36,0l9.26,9.26a1.5,1.5,0,0,0,2.12,0,1.49,1.49,0,0,0,0-2.12L14.3,12.18a.25.25,0,0,1,0-.36l9.26-9.26A1.5,1.5,0,0,0,21.44.44L12.18,9.7a.25.25,0,0,1-.36,0L2.56.44A1.5,1.5,0,0,0,.44,2.56L9.7,11.82a.25.25,0,0,1,0,.36Z'
              fill='currentColor'
            />
          </svg>
        </button>
      </div>
    );
  }
  if (type === 'input')
    return (
      <input
        type='text'
        disabled={disabled}
        placeholder={name}
        value={data?.url || value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        className='rounded-md border-jacarta-200 capitalize'
      />
    );

  return null;
};

export default OptionInput;
