import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import { GiCardExchange } from 'react-icons/gi';
import { shortenAddress } from '../../../helpers/address';
import { numFormatter } from '../../../lib/numberFormatter';
import useGetActivities from '../../../hooks/useGetActivities';
import { ChainIcon } from '../../Icon';
import { getTokenName } from '../../../helpers/getChainName';
import { LoadingSpinner } from '../../loadingSpinner';
import { constants } from '../../../helpers/constants';

const UserActivity = ({ options, data }) => {
  const [activities, setActivities] = useState([]);
  const { data: activity, isLoading } = useGetActivities(
    'updatedAt:desc',
    50,
    1,
    data?.events,
    data?.users,
    data?.collections,
    '',
  );

  useEffect(() => {
    if (activity?.results) {
      setActivities(activity?.results);
    }
  }, [activity]);
  const columns = [
    { id: 'type', label: 'Type', minWidth: 50 },
    { id: 'items', label: 'Items', minWidth: 50, colspan: 2 },
    { id: 'price', label: 'Price', minWidth: 50 },
    { id: 'from', label: 'From', minWidth: 50 },
    { id: 'to', label: 'To', minWidth: 50 },
    { id: 'date', label: 'Date', minWidth: 50 },
  ];

  return (
    <>
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={column.id}
              align={column.align}
              style={{ minWidth: column.minWidth }}
              classes={{
                root: 'dark:bg-jacarta-600 bg-light-base dark:text-jacarta-100 text-jacarta-700',
              }}
            >
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {isLoading && (
          <TableRow>
            <TableCell align='center' rowSpan={6} colSpan={6}>
              <LoadingSpinner className='flex justify-center' />
            </TableCell>
          </TableRow>
        )}
        {!isLoading &&
          activities?.map((row, index) => {
            let {
              item,
              itemId,
              item: { metadata },
              collections: collectionInfo,
            } = row;
            metadata = JSON.parse(metadata);
            let image;
            if (collectionInfo?.isDNFT) {
              image = collectionInfo.thumbnailAPI + itemId;
            } else {
              image = metadata?.image;
            }

            return (
              <TableRow key={index}>
                <TableCell
                  classes={{ root: 'dark:bg-jacarta-600 dark:text-jacarta-100 text-jacarta-700' }}
                >
                  <Tooltip
                    title={row?.eventType}
                    classes={{
                      tooltip: 'capitalize',
                    }}
                  >
                    <span className='flex items-center space-x-2'>
                      <svg className='icon inline h-6 w-6 fill-jacarta-700 dark:fill-white'>
                        {row?.eventType === 'changed' ? (
                          <GiCardExchange size={24} />
                        ) : (
                          <use xlinkHref={`/icons.svg#icon-${row?.eventType}`} />
                        )}
                      </svg>
                      <span>{row?.eventType}</span>
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell
                  classes={{ root: 'dark:bg-jacarta-600 dark:text-jacarta-100 text-jacarta-700' }}
                >
                  <img
                    src={image}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = constants.helpers.noImage;
                    }}
                    alt={'photo'}
                    className='rounded-2lg object-cover'
                    height={48}
                    width={48}
                  />
                </TableCell>
                <TableCell
                  classes={{ root: 'dark:bg-jacarta-600 dark:text-jacarta-100 text-jacarta-700' }}
                >
                  {row?.price ? (
                    <span className='flex items-center whitespace-nowrap rounded-md border-jacarta-100 py-1 dark:border-jacarta-600'>
                      <ChainIcon
                        name={row?.item?.chain}
                        tooltip={getTokenName(row?.item?.chain)}
                        sideGap={true}
                      />

                      <span className='text text-sm font-medium tracking-tight'>
                        {numFormatter(row?.price)}
                      </span>
                    </span>
                  ) : (
                    '-'
                  )}
                </TableCell>
                <TableCell
                  classes={{ root: 'dark:bg-jacarta-600 dark:text-jacarta-100 text-jacarta-700' }}
                >
                  {row?.from && shortenAddress(row?.from)}
                </TableCell>
                <TableCell
                  classes={{ root: 'dark:bg-jacarta-600 dark:text-jacarta-100 text-jacarta-700' }}
                >
                  {row?.to && shortenAddress(row?.to)}
                </TableCell>
                <TableCell
                  classes={{ root: 'dark:bg-jacarta-600 dark:text-jacarta-100 text-jacarta-700' }}
                >
                  {new Date(row?.createdAt).toLocaleDateString('en-US')}
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </>
  );
};

export default UserActivity;
