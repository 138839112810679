import React, { useEffect, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import {
  Autocomplete,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@mui/material';
import axios from 'axios';
import pairs from './tv/pairs';
import { useTheme } from 'next-themes';

const AdvancedRealTimeChartNoSSR = dynamic(
  () => import('react-ts-tradingview-widgets').then((w) => w.AdvancedRealTimeChart),
  {
    ssr: false,
  },
);

const optionList = [
  { value: '1', label: '1 Minutes' },
  { value: '3', label: '3 Minutes' },
  { value: '30', label: '30 Minutes' },
  { value: '180', label: '3 Hours' },
  { value: 'W', label: '1 Week' },
  { value: 'ALL', label: 'ALL' },
  { value: 'YTD', label: 'YTD' },
];

const TradingViewWidget = ({ id, editMode, setData, item }) => {
  const { theme, setTheme } = useTheme();

  const [symbol, setSymbol] = useState(item?.data?.symbol || { symbol: 'BNBUSDT' });
  const [symbols, setSymbols] = useState([]);
  const [range, setRange] = useState(item?.data?.range || '1');
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    (async () => {
      // let all_symbols = await getAllSymbols()
      // let all_symbols = [
      //     {symbol: "BNBUSDT"}
      // ]
      // all_symbols = all_symbols.map(symbol => {
      //     symbol.label = symbol.symbol
      //     return symbol
      // })
      setSymbols(
        pairs.map((symbol) => {
          symbol.label = symbol.name;
          return symbol;
        }),
      );
    })();

    return () => {};
  }, []);

  return (
    <div className='tradingview-widget-container h-[80%] bg-transparent'>
      {/* <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Pair</InputLabel>
                    <Select
                        // value={age}
                        label="Pair"
                        onChange={(e) => setSymbol(e.target.value)}
                        value={symbol}
                    >
                        {
                            symbols && symbols.map((symbol, i) => <MenuItem key={i} value={symbol.symbol}> {symbol.symbol} </MenuItem>)
                        }
                    </Select>
                </FormControl> */}
      <Grid container gap={1} sx={{ m: 2 }} className='ml-0'>
        {editMode ? (
          <Autocomplete
            disabled={!editMode}
            disablePortal
            options={symbols}
            onChange={(e, newValue) => {
              setSymbol(newValue);
              setIsSelected(true);
              setData(id, 'symbol', newValue);
            }}
            sx={{ width: 300 }}
            className={
              'h-12 rounded-lg border border-jacarta-100 bg-jacarta-50 px-4 text-sm  text-jacarta-700 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:text-white'
            }
            renderInput={(params) => {
              params.InputProps.className +=
                ' !bg-transparent !p-0 !m-0 !h-full text-jacarta-700 dark:text-white';
              params.inputProps.className +=
                ' !bg-transparent !m-0 !p-3 !h-full text-jacarta-700 dark:text-white';
              // params.InputLabelProps.style = { display: "hidden"}

              return (
                <TextField
                  {...params}
                  className={'!bg-transparent !text-red'}
                  label={
                    isSelected
                      ? undefined
                      : item?.data?.symbol
                      ? item?.data?.symbol?.name
                      : 'BNBUSDT'
                  }
                  InputLabelProps={{
                    className: 'text-jacarta-700 dark:text-white !top-[-4px]',
                  }}
                />
              );
            }}
          />
        ) : (
          <div className='h-fit items-center rounded-lg pr-4 text-jacarta-700 dark:text-white'>
            Pair:{' '}
            {isSelected ? undefined : item?.data?.symbol ? item?.data?.symbol?.name : 'BNBUSDT'}
          </div>
        )}
        {editMode ? (
          <select
            disabled={!editMode}
            className='h-12 rounded-lg border border-jacarta-100 bg-jacarta-50 px-4 text-sm  text-jacarta-700 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:text-white'
            value={range}
            onChange={(e) => {
              setRange(e.target.value);
              setData(id, 'range', e.target.value);
            }}
          >
            {optionList.map((option, i) => (
              <option key={i + option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        ) : (
          <div className='h-fit items-center rounded-lg px-4  text-jacarta-700 dark:text-white'>
            Time Range: {optionList.find((option) => option.value === range)?.label}
          </div>
        )}
      </Grid>
      <AdvancedRealTimeChartNoSSR
        autosize
        // hide_side_toolbar
        hide_top_toolbar
        hide_legend
        withdateranges={false}
        symbol={symbol?.symbol}
        interval={range}
        theme={theme === 'dark' ? 'dark' : 'light'}
        // enabled_features={"header_saveload"}
      />
    </div>
  );
};

export default TradingViewWidget;
