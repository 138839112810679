import { CryptoCurrencyMarket, Ticker } from 'react-ts-tradingview-widgets';
import { useTheme } from 'next-themes';
import pairs from './tv/pairs';
import { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';

const TickerWidget = ({ editMode, id, item, setData }) => {
  const { theme, setTheme } = useTheme();

  const [symbols, setSymbols] = useState(item?.data?.symbols || []);

  const [allSymbols, setAllSymbols] = useState([]);

  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    let symbols = pairs.map((pair) => {
      pair.proName = pair.symbol;
      pair.title = pair.name;
      pair.label = pair.name;
      return pair;
    });

    setAllSymbols(symbols);

    return () => {};
  }, []);

  return (
    <div>
      {editMode && (
        <Autocomplete
          disabled={!editMode}
          options={allSymbols}
          value={symbols}
          getOptionSelected={(option, value) => value.proName === option.proName}
          multiple
          onChange={(e, newValue) => {
            if (symbols.length >= 5 && newValue.length > symbols.length) {
              return;
            }
            setSymbols(newValue);
            setIsSelected(true);
            setData(id, 'symbols', newValue);
          }}
          className={
            'h-12 rounded-lg border border-jacarta-100 bg-jacarta-50 px-4 text-sm  text-jacarta-700 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:text-white'
          }
          renderInput={(params) => {
            params.InputProps.className +=
              ' !bg-transparent !p-0 !m-0 !h-full text-jacarta-700 dark:text-white';
            params.inputProps.className +=
              ' !bg-transparent !m-0 !p-3 !h-full text-jacarta-700 dark:text-white';
            // params.InputLabelProps.style = { display: "hidden"}

            return (
              <TextField
                {...params}
                className={'!bg-transparent !text-red'}
                label={
                  isSelected ? undefined : item?.data?.symbol ? item?.data?.symbol?.name : 'BNBUSDT'
                }
                InputLabelProps={{
                  sx: {
                    color: 'black',
                  },
                }}
              />
            );
          }}
        />
      )}

      <Ticker colorTheme={theme} showSymbolLogo symbols={symbols} />
    </div>
  );
};

export default TickerWidget;
