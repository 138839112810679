import React from 'react';
import Image from 'next/image';
import Gravatar from 'react-gravatar';
import useGetTopTraders from '../../../hooks/useGetTopTraders';
import { LoadingSpinner } from '../../loadingSpinner';
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Icon from '../../Icon';
import { normalizeNumber } from '../../../helpers/digits';
import { shortenAddress } from '../../../helpers/address';
import { MD5 } from '../../../lib/md5';
import { getCdnUrl } from '../../../lib/cdnUrl';

const TopTraders = () => {
  const { data: topTraders, isLoading } = useGetTopTraders();
  const columns = [
    { id: 'name', label: 'Name', minWidth: 50, colspan: 2 },
    { id: 'volume', label: 'Volume', minWidth: 50 },
  ];

  return (
    <>
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={column.id}
              align={column.align}
              colSpan={column.colspan}
              style={{ minWidth: column.minWidth }}
              classes={{
                root: 'dark:bg-jacarta-600 bg-light-base dark:text-jacarta-100 text-jacarta-700',
              }}
            >
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {isLoading && (
          <TableRow>
            <TableCell align='center' rowSpan={5} colSpan={3}>
              <LoadingSpinner className='flex justify-center' />
            </TableCell>
          </TableRow>
        )}
        {!isLoading &&
          topTraders?.map((row, index) => {
            return (
              <TableRow key={index}>
                <TableCell
                  classes={{ root: 'dark:bg-jacarta-600 dark:text-jacarta-100 text-jacarta-700' }}
                >
                  {row?.user?.profilePhoto ? (
                    <Image
                      src={getCdnUrl(row?.user?.profilePhoto)}
                      alt={row?.user?.name}
                      className='rounded-2lg'
                      height={48}
                      width={48}
                      objectFit='cover'
                    />
                  ) : (
                    <Gravatar
                      email={MD5(row?.user?.walletAddress)}
                      className='rounded-2lg'
                      size={48}
                    />
                  )}
                </TableCell>
                <TableCell
                  classes={{ root: 'dark:bg-jacarta-600 dark:text-jacarta-100 text-jacarta-700' }}
                >
                  {row?.user?.name ? row?.user?.name : shortenAddress(row?.user?.walletAddress)}
                </TableCell>
                <TableCell
                  classes={{ root: 'dark:bg-jacarta-600 dark:text-jacarta-100 text-jacarta-700' }}
                >
                  <span className='dark:border-jacarta-600 border-jacarta-100 flex items-center whitespace-nowrap rounded-md py-1'>
                    <Icon name='bnb' tooltip='BNB' />

                    <span className='text text-sm font-medium tracking-tight'>
                      {/*{volume}<br/>*/}
                      {normalizeNumber(row?.volume)}
                      {/*{normalizeNumber(volume)}*/}
                    </span>
                  </span>
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </>
  );
};

export default TopTraders;
