import React, { createRef, useEffect } from 'react';
import OptionInput from './Table/OptionInput';

const TwitterWidget = ({ id, options, data, setData, editMode }) => {
  let timer = createRef();
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://platform.twitter.com/widgets.js';
    document.head.appendChild(script);
  }, []);

  useEffect(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      if (document.querySelector('.twitter-timeline > iframe')) {
        document.querySelector(
          '.twitter-timeline > iframe',
        ).src = `https://syndication.twitter.com/srv/timeline-profile/screen-name/${
          data?.url
        }?&lang=en&suppress_response_codes=true&screen_name=${data?.url}&t=${new Date().getTime()}`;
      }
    }, 1000);
    return () => clearTimeout(timer.current);
  }, [data]);
  return (
    <div className='h-full'>
      <div className='my-2 flex space-x-2 px-3'>
        {options.map((option, index) => {
          return (
            <OptionInput
              key={index}
              id={id}
              option={option}
              data={data}
              setData={setData}
              disabled={!editMode}
            />
          );
        })}
      </div>
      <div className='scrollbar-custom h-full overflow-y-auto'>
        <a
          className='twitter-timeline'
          data-chrome=' noheader noborders nofooter'
          href={`https://twitter.com/${data?.url || 'Bit5Official'}`}
        />
      </div>
    </div>
  );
};

export default TwitterWidget;
