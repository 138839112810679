const pairs = [
  { name: 'ENJETH', symbol: 'BINANCE:ENJETH' },
  { name: 'NULSUSDT', symbol: 'BINANCE:NULSUSDT' },
  { name: 'BTCUSDT', symbol: 'BINANCE:BTCUSDT' },
  { name: 'ETHUSDT', symbol: 'BINANCE:ETHUSDT' },
  { name: 'TFUELBTC', symbol: 'BINANCE:TFUELBTC' },
  { name: 'TFUELUSDT', symbol: 'BINANCE:TFUELUSDT' },
  { name: 'BNBUSDT', symbol: 'BINANCE:BNBUSDT' },
  { name: 'BTCBUSD', symbol: 'BINANCE:BTCBUSD' },
  { name: 'ETHBTC', symbol: 'BINANCE:ETHBTC' },
  { name: 'BUSDUSDT', symbol: 'BINANCE:BUSDUSDT' },
  { name: 'LINKUSDT', symbol: 'BINANCE:LINKUSDT' },
  { name: 'COSBTC', symbol: 'BINANCE:COSBTC' },
  { name: 'EOSUSDT', symbol: 'BINANCE:EOSUSDT' },
  { name: 'BCHUSDT', symbol: 'BINANCE:BCHUSDT' },
  { name: 'THETABTC', symbol: 'BINANCE:THETABTC' },
  { name: 'XRPUSDT', symbol: 'BINANCE:XRPUSDT' },
  { name: 'THETAUSDT', symbol: 'BINANCE:THETAUSDT' },
  { name: 'ETCUSDT', symbol: 'BINANCE:ETCUSDT' },
  { name: 'COSUSDT', symbol: 'BINANCE:COSUSDT' },
  { name: 'LTCUSDT', symbol: 'BINANCE:LTCUSDT' },
  { name: 'BTCUSDC', symbol: 'BINANCE:BTCUSDC' },
  { name: 'OMGUSDT', symbol: 'BINANCE:OMGUSDT' },
  { name: 'BNBBTC', symbol: 'BINANCE:BNBBTC' },
  { name: 'ADAUSDT', symbol: 'BINANCE:ADAUSDT' },
  { name: 'MATICUSDT', symbol: 'BINANCE:MATICUSDT' },
  { name: 'XTZUSDT', symbol: 'BINANCE:XTZUSDT' },
  { name: 'LINKBTC', symbol: 'BINANCE:LINKBTC' },
  { name: 'MATICBTC', symbol: 'BINANCE:MATICBTC' },
  { name: 'OMGBTC', symbol: 'BINANCE:OMGBTC' },
  { name: 'TRXUSDT', symbol: 'BINANCE:TRXUSDT' },
  { name: 'IOTXBTC', symbol: 'BINANCE:IOTXBTC' },
  { name: 'USDCUSDT', symbol: 'BINANCE:USDCUSDT' },
  { name: 'XRPBTC', symbol: 'BINANCE:XRPBTC' },
  { name: 'FUELBTC', symbol: 'BINANCE:FUELBTC' },
  { name: 'VETUSDT', symbol: 'BINANCE:VETUSDT' },
  { name: 'BCHBTC', symbol: 'BINANCE:BCHBTC' },
  { name: 'IOTXUSDT', symbol: 'BINANCE:IOTXUSDT' },
  { name: 'EOSBTC', symbol: 'BINANCE:EOSBTC' },
  { name: 'LTCBTC', symbol: 'BINANCE:LTCBTC' },
  { name: 'ONTUSDT', symbol: 'BINANCE:ONTUSDT' },
  { name: 'NEOUSDT', symbol: 'BINANCE:NEOUSDT' },
  { name: 'RVNBTC', symbol: 'BINANCE:RVNBTC' },
  { name: 'XTZBTC', symbol: 'BINANCE:XTZBTC' },
  { name: 'XMRBTC', symbol: 'BINANCE:XMRBTC' },
  { name: 'ICXUSDT', symbol: 'BINANCE:ICXUSDT' },
  { name: 'ZILUSDT', symbol: 'BINANCE:ZILUSDT' },
  { name: 'BNTBTC', symbol: 'BINANCE:BNTBTC' },
  { name: 'KNCBTC', symbol: 'BINANCE:KNCBTC' },
  { name: 'QTUMUSDT', symbol: 'BINANCE:QTUMUSDT' },
  { name: 'ZILBTC', symbol: 'BINANCE:ZILBTC' },
  { name: 'ADABTC', symbol: 'BINANCE:ADABTC' },
  { name: 'DASHUSDT', symbol: 'BINANCE:DASHUSDT' },
  { name: 'XMRUSDT', symbol: 'BINANCE:XMRUSDT' },
  { name: 'XLMUSDT', symbol: 'BINANCE:XLMUSDT' },
  { name: 'ERDUSDT', symbol: 'BINANCE:ERDUSDT' },
  { name: 'ETCBTC', symbol: 'BINANCE:ETCBTC' },
  { name: 'TUSDUSDT', symbol: 'BINANCE:TUSDUSDT' },
  { name: 'ENJBTC', symbol: 'BINANCE:ENJBTC' },
  { name: 'BTCTUSD', symbol: 'BINANCE:BTCTUSD' },
  { name: 'IOSTUSDT', symbol: 'BINANCE:IOSTUSDT' },
  { name: 'ZECUSDT', symbol: 'BINANCE:ZECUSDT' },
  { name: 'PAXUSDT', symbol: 'BINANCE:PAXUSDT' },
  { name: 'ALGOUSDT', symbol: 'BINANCE:ALGOUSDT' },
  { name: 'BTCPAX', symbol: 'BINANCE:BTCPAX' },
  { name: 'POLYBTC', symbol: 'BINANCE:POLYBTC' },
  { name: 'ICXBTC', symbol: 'BINANCE:ICXBTC' },
  { name: 'AMBBTC', symbol: 'BINANCE:AMBBTC' },
  { name: 'RVNUSDT', symbol: 'BINANCE:RVNUSDT' },
  { name: 'ALGOBTC', symbol: 'BINANCE:ALGOBTC' },
  { name: 'BNTUSDT', symbol: 'BINANCE:BNTUSDT' },
  { name: 'HOTUSDT', symbol: 'BINANCE:HOTUSDT' },
  { name: 'COTIBTC', symbol: 'BINANCE:COTIBTC' },
  { name: 'BATUSDT', symbol: 'BINANCE:BATUSDT' },
  { name: 'PHBBTC', symbol: 'BINANCE:PHBBTC' },
  { name: 'ENJUSDT', symbol: 'BINANCE:ENJUSDT' },
  { name: 'ETHBUSD', symbol: 'BINANCE:ETHBUSD' },
  { name: 'ZRXBTC', symbol: 'BINANCE:ZRXBTC' },
  { name: 'OGNBTC', symbol: 'BINANCE:OGNBTC' },
  { name: 'TNTBTC', symbol: 'BINANCE:TNTBTC' },
  { name: 'ZRXUSDT', symbol: 'BINANCE:ZRXUSDT' },
  { name: 'TRXBTC', symbol: 'BINANCE:TRXBTC' },
  { name: 'TCTBTC', symbol: 'BINANCE:TCTBTC' },
  { name: 'KAVAUSDT', symbol: 'BINANCE:KAVAUSDT' },
  { name: 'BTTUSDT', symbol: 'BINANCE:BTTUSDT' },
  { name: 'NKNBTC', symbol: 'BINANCE:NKNBTC' },
  { name: 'OGNUSDT', symbol: 'BINANCE:OGNUSDT' },
  { name: 'HOTBTC', symbol: 'BINANCE:HOTBTC' },
  { name: 'VETBTC', symbol: 'BINANCE:VETBTC' },
  { name: 'BATBTC', symbol: 'BINANCE:BATBTC' },
  { name: 'AIONBTC', symbol: 'BINANCE:AIONBTC' },
  { name: 'CHRBTC', symbol: 'BINANCE:CHRBTC' },
  { name: 'KAVABTC', symbol: 'BINANCE:KAVABTC' },
  { name: 'ETHUSDC', symbol: 'BINANCE:ETHUSDC' },
  { name: 'ZECBTC', symbol: 'BINANCE:ZECBTC' },
  { name: 'NEOBTC', symbol: 'BINANCE:NEOBTC' },
  { name: 'FETUSDT', symbol: 'BINANCE:FETUSDT' },
  { name: 'XLMBTC', symbol: 'BINANCE:XLMBTC' },
  { name: 'XVGBTC', symbol: 'BINANCE:XVGBTC' },
  { name: 'ONTBTC', symbol: 'BINANCE:ONTBTC' },
  { name: 'NANOUSDT', symbol: 'BINANCE:NANOUSDT' },
  { name: 'ATOMUSDT', symbol: 'BINANCE:ATOMUSDT' },
  { name: 'RENBTC', symbol: 'BINANCE:RENBTC' },
  { name: 'LTOBTC', symbol: 'BINANCE:LTOBTC' },
  { name: 'BANDUSDT', symbol: 'BINANCE:BANDUSDT' },
  { name: 'FTMBTC', symbol: 'BINANCE:FTMBTC' },
  { name: 'WRXBTC', symbol: 'BINANCE:WRXBTC' },
  { name: 'DASHBTC', symbol: 'BINANCE:DASHBTC' },
  { name: 'HBARBTC', symbol: 'BINANCE:HBARBTC' },
  { name: 'IOTAUSDT', symbol: 'BINANCE:IOTAUSDT' },
  { name: 'NANOBTC', symbol: 'BINANCE:NANOBTC' },
  { name: 'WTCBTC', symbol: 'BINANCE:WTCBTC' },
  { name: 'CNDBTC', symbol: 'BINANCE:CNDBTC' },
  { name: 'WRXUSDT', symbol: 'BINANCE:WRXUSDT' },
  { name: 'NPXSUSDT', symbol: 'BINANCE:NPXSUSDT' },
  { name: 'IOSTBTC', symbol: 'BINANCE:IOSTBTC' },
  { name: 'AIONUSDT', symbol: 'BINANCE:AIONUSDT' },
  { name: 'QTUMBTC', symbol: 'BINANCE:QTUMBTC' },
  { name: 'DUSKBTC', symbol: 'BINANCE:DUSKBTC' },
  { name: 'NASBTC', symbol: 'BINANCE:NASBTC' },
  { name: 'ETHPAX', symbol: 'BINANCE:ETHPAX' },
  { name: 'CHZBTC', symbol: 'BINANCE:CHZBTC' },
  { name: 'HCUSDT', symbol: 'BINANCE:HCUSDT' },
  { name: 'POABTC', symbol: 'BINANCE:POABTC' },
  { name: 'WAVESBTC', symbol: 'BINANCE:WAVESBTC' },
  { name: 'ARDRBTC', symbol: 'BINANCE:ARDRBTC' },
  { name: 'XEMBTC', symbol: 'BINANCE:XEMBTC' },
  { name: 'FTMUSDT', symbol: 'BINANCE:FTMUSDT' },
  { name: 'CTSIBTC', symbol: 'BINANCE:CTSIBTC' },
  { name: 'VIBBTC', symbol: 'BINANCE:VIBBTC' },
  { name: 'GASBTC', symbol: 'BINANCE:GASBTC' },
  { name: 'COTIUSDT', symbol: 'BINANCE:COTIUSDT' },
  { name: 'HBARUSDT', symbol: 'BINANCE:HBARUSDT' },
  { name: 'WABIBTC', symbol: 'BINANCE:WABIBTC' },
  { name: 'CHRUSDT', symbol: 'BINANCE:CHRUSDT' },
  { name: 'DATABTC', symbol: 'BINANCE:DATABTC' },
  { name: 'NKNUSDT', symbol: 'BINANCE:NKNUSDT' },
  { name: 'GTOBTC', symbol: 'BINANCE:GTOBTC' },
  { name: 'SOLBTC', symbol: 'BINANCE:SOLBTC' },
  { name: 'THETAETH', symbol: 'BINANCE:THETAETH' },
  { name: 'HCBTC', symbol: 'BINANCE:HCBTC' },
  { name: 'BANDBTC', symbol: 'BINANCE:BANDBTC' },
  { name: 'TOMOBTC', symbol: 'BINANCE:TOMOBTC' },
  { name: 'BTCNGN', symbol: 'BINANCE:BTCNGN' },
  { name: 'TROYBTC', symbol: 'BINANCE:TROYBTC' },
  { name: 'WAVESUSDT', symbol: 'BINANCE:WAVESUSDT' },
  { name: 'BTCEUR', symbol: 'BINANCE:BTCEUR' },
  { name: 'IOTABTC', symbol: 'BINANCE:IOTABTC' },
  { name: 'HIVEBTC', symbol: 'BINANCE:HIVEBTC' },
  { name: 'CELRBTC', symbol: 'BINANCE:CELRBTC' },
  { name: 'SCBTC', symbol: 'BINANCE:SCBTC' },
  { name: 'IOTXETH', symbol: 'BINANCE:IOTXETH' },
  { name: 'WINUSDT', symbol: 'BINANCE:WINUSDT' },
  { name: 'ARKBTC', symbol: 'BINANCE:ARKBTC' },
  { name: 'BNBBUSD', symbol: 'BINANCE:BNBBUSD' },
  { name: 'ERDBTC', symbol: 'BINANCE:ERDBTC' },
  { name: 'SNTBTC', symbol: 'BINANCE:SNTBTC' },
  { name: 'BEAMUSDT', symbol: 'BINANCE:BEAMUSDT' },
  { name: 'BEAMBTC', symbol: 'BINANCE:BEAMBTC' },
  { name: 'STEEMBTC', symbol: 'BINANCE:STEEMBTC' },
  { name: 'SNGLSBTC', symbol: 'BINANCE:SNGLSBTC' },
  { name: 'BNBETH', symbol: 'BINANCE:BNBETH' },
  { name: 'MANABTC', symbol: 'BINANCE:MANABTC' },
  { name: 'HIVEUSDT', symbol: 'BINANCE:HIVEUSDT' },
  { name: 'PERLBTC', symbol: 'BINANCE:PERLBTC' },
  { name: 'CELRUSDT', symbol: 'BINANCE:CELRUSDT' },
  { name: 'STXUSDT', symbol: 'BINANCE:STXUSDT' },
  { name: 'ENGBTC', symbol: 'BINANCE:ENGBTC' },
  { name: 'BCDBTC', symbol: 'BINANCE:BCDBTC' },
  { name: 'MTLBTC', symbol: 'BINANCE:MTLBTC' },
  { name: 'ONEBTC', symbol: 'BINANCE:ONEBTC' },
  { name: 'STXBTC', symbol: 'BINANCE:STXBTC' },
  { name: 'TRXETH', symbol: 'BINANCE:TRXETH' },
  { name: 'ARDRUSDT', symbol: 'BINANCE:ARDRUSDT' },
  { name: 'RENUSDT', symbol: 'BINANCE:RENUSDT' },
  { name: 'DREPBTC', symbol: 'BINANCE:DREPBTC' },
  { name: 'ATOMBTC', symbol: 'BINANCE:ATOMBTC' },
  { name: 'LSKBTC', symbol: 'BINANCE:LSKBTC' },
  { name: 'CHZUSDT', symbol: 'BINANCE:CHZUSDT' },
  { name: 'ARNBTC', symbol: 'BINANCE:ARNBTC' },
  { name: 'QKCBTC', symbol: 'BINANCE:QKCBTC' },
  { name: 'ZENBTC', symbol: 'BINANCE:ZENBTC' },
  { name: 'ONEUSDT', symbol: 'BINANCE:ONEUSDT' },
  { name: 'OMGETH', symbol: 'BINANCE:OMGETH' },
  { name: 'COSBNB', symbol: 'BINANCE:COSBNB' },
  { name: 'CDTBTC', symbol: 'BINANCE:CDTBTC' },
  { name: 'DUSKUSDT', symbol: 'BINANCE:DUSKUSDT' },
  { name: 'BCHPAX', symbol: 'BINANCE:BCHPAX' },
  { name: 'TOMOUSDT', symbol: 'BINANCE:TOMOUSDT' },
  { name: 'FETBTC', symbol: 'BINANCE:FETBTC' },
  { name: 'KNCETH', symbol: 'BINANCE:KNCETH' },
  { name: 'ETHTUSD', symbol: 'BINANCE:ETHTUSD' },
  { name: 'STRATBTC', symbol: 'BINANCE:STRATBTC' },
  { name: 'FUNBTC', symbol: 'BINANCE:FUNBTC' },
  { name: 'MITHUSDT', symbol: 'BINANCE:MITHUSDT' },
  { name: 'DOCKBTC', symbol: 'BINANCE:DOCKBTC' },
  { name: 'PERLUSDT', symbol: 'BINANCE:PERLUSDT' },
  { name: 'KMDBTC', symbol: 'BINANCE:KMDBTC' },
  { name: 'ZILETH', symbol: 'BINANCE:ZILETH' },
  { name: 'MBLUSDT', symbol: 'BINANCE:MBLUSDT' },
  { name: 'MCOBTC', symbol: 'BINANCE:MCOBTC' },
  { name: 'BTGBTC', symbol: 'BINANCE:BTGBTC' },
  { name: 'STPTBTC', symbol: 'BINANCE:STPTBTC' },
  { name: 'LINKETH', symbol: 'BINANCE:LINKETH' },
  { name: 'BNTETH', symbol: 'BINANCE:BNTETH' },
  { name: 'WANBTC', symbol: 'BINANCE:WANBTC' },
  { name: 'NPXSETH', symbol: 'BINANCE:NPXSETH' },
  { name: 'USDTTRY', symbol: 'BINANCE:USDTTRY' },
  { name: 'GOBTC', symbol: 'BINANCE:GOBTC' },
  { name: 'HOTETH', symbol: 'BINANCE:HOTETH' },
  { name: 'AEBTC', symbol: 'BINANCE:AEBTC' },
  { name: 'EOSETH', symbol: 'BINANCE:EOSETH' },
  { name: 'ARPABTC', symbol: 'BINANCE:ARPABTC' },
  { name: 'CTSIUSDT', symbol: 'BINANCE:CTSIUSDT' },
  { name: 'POWRBTC', symbol: 'BINANCE:POWRBTC' },
  { name: 'DATAUSDT', symbol: 'BINANCE:DATAUSDT' },
  { name: 'TROYUSDT', symbol: 'BINANCE:TROYUSDT' },
  { name: 'SKYBTC', symbol: 'BINANCE:SKYBTC' },
  { name: 'BTCTRY', symbol: 'BINANCE:BTCTRY' },
  { name: 'BCHTUSD', symbol: 'BINANCE:BCHTUSD' },
  { name: 'RLCBTC', symbol: 'BINANCE:RLCBTC' },
  { name: 'POEBTC', symbol: 'BINANCE:POEBTC' },
  { name: 'VITEBTC', symbol: 'BINANCE:VITEBTC' },
  { name: 'WTCUSDT', symbol: 'BINANCE:WTCUSDT' },
  { name: 'DENTUSDT', symbol: 'BINANCE:DENTUSDT' },
  { name: 'FTTBTC', symbol: 'BINANCE:FTTBTC' },
  { name: 'WANUSDT', symbol: 'BINANCE:WANUSDT' },
  { name: 'MITHBTC', symbol: 'BINANCE:MITHBTC' },
  { name: 'BUSDNGN', symbol: 'BINANCE:BUSDNGN' },
  { name: 'TCTUSDT', symbol: 'BINANCE:TCTUSDT' },
  { name: 'LENDBTC', symbol: 'BINANCE:LENDBTC' },
  { name: 'XRPETH', symbol: 'BINANCE:XRPETH' },
  { name: 'XZCBTC', symbol: 'BINANCE:XZCBTC' },
  { name: 'QLCBTC', symbol: 'BINANCE:QLCBTC' },
  { name: 'ANKRUSDT', symbol: 'BINANCE:ANKRUSDT' },
  { name: 'LOOMBTC', symbol: 'BINANCE:LOOMBTC' },
  { name: 'PPTBTC', symbol: 'BINANCE:PPTBTC' },
  { name: 'BTTTRX', symbol: 'BINANCE:BTTTRX' },
  { name: 'STORMUSDT', symbol: 'BINANCE:STORMUSDT' },
  { name: 'DOGEUSDT', symbol: 'BINANCE:DOGEUSDT' },
  { name: 'ELFBTC', symbol: 'BINANCE:ELFBTC' },
  { name: 'AGIBTC', symbol: 'BINANCE:AGIBTC' },
  { name: 'LTCUSDC', symbol: 'BINANCE:LTCUSDC' },
  { name: 'OSTBTC', symbol: 'BINANCE:OSTBTC' },
  { name: 'REPBTC', symbol: 'BINANCE:REPBTC' },
  { name: 'THETABNB', symbol: 'BINANCE:THETABNB' },
  { name: 'ANKRBTC', symbol: 'BINANCE:ANKRBTC' },
  { name: 'KEYUSDT', symbol: 'BINANCE:KEYUSDT' },
  { name: 'GNTBTC', symbol: 'BINANCE:GNTBTC' },
  { name: 'MDABTC', symbol: 'BINANCE:MDABTC' },
  { name: 'TNBBTC', symbol: 'BINANCE:TNBBTC' },
  { name: 'STORJBTC', symbol: 'BINANCE:STORJBTC' },
  { name: 'CVCBTC', symbol: 'BINANCE:CVCBTC' },
  { name: 'STPTUSDT', symbol: 'BINANCE:STPTUSDT' },
  { name: 'APPCBTC', symbol: 'BINANCE:APPCBTC' },
  { name: 'MBLBTC', symbol: 'BINANCE:MBLBTC' },
  { name: 'EVXBTC', symbol: 'BINANCE:EVXBTC' },
  { name: 'XRPBUSD', symbol: 'BINANCE:XRPBUSD' },
  { name: 'ONGBTC', symbol: 'BINANCE:ONGBTC' },
  { name: 'LSKUSDT', symbol: 'BINANCE:LSKUSDT' },
  { name: 'EOSBNB', symbol: 'BINANCE:EOSBNB' },
  { name: 'VITEUSDT', symbol: 'BINANCE:VITEUSDT' },
  { name: 'ARPAUSDT', symbol: 'BINANCE:ARPAUSDT' },
  { name: 'ADAETH', symbol: 'BINANCE:ADAETH' },
  { name: 'GTOUSDT', symbol: 'BINANCE:GTOUSDT' },
  { name: 'TRXBNB', symbol: 'BINANCE:TRXBNB' },
  { name: 'BCHBUSD', symbol: 'BINANCE:BCHBUSD' },
  { name: 'LTCETH', symbol: 'BINANCE:LTCETH' },
  { name: 'BTSBTC', symbol: 'BINANCE:BTSBTC' },
  { name: 'MCOUSDT', symbol: 'BINANCE:MCOUSDT' },
  { name: 'ONGUSDT', symbol: 'BINANCE:ONGUSDT' },
  { name: 'SNMBTC', symbol: 'BINANCE:SNMBTC' },
  { name: 'ZILBNB', symbol: 'BINANCE:ZILBNB' },
  { name: 'MTLUSDT', symbol: 'BINANCE:MTLUSDT' },
  { name: 'CMTBTC', symbol: 'BINANCE:CMTBTC' },
  { name: 'LUNBTC', symbol: 'BINANCE:LUNBTC' },
  { name: 'STRATUSDT', symbol: 'BINANCE:STRATUSDT' },
  { name: 'PIVXBTC', symbol: 'BINANCE:PIVXBTC' },
  { name: 'MTHBTC', symbol: 'BINANCE:MTHBTC' },
  { name: 'LTOUSDT', symbol: 'BINANCE:LTOUSDT' },
  { name: 'SOLBUSD', symbol: 'BINANCE:SOLBUSD' },
  { name: 'WINTRX', symbol: 'BINANCE:WINTRX' },
  { name: 'VIBEBTC', symbol: 'BINANCE:VIBEBTC' },
  { name: 'EDOBTC', symbol: 'BINANCE:EDOBTC' },
  { name: 'XZCUSDT', symbol: 'BINANCE:XZCUSDT' },
  { name: 'VIABTC', symbol: 'BINANCE:VIABTC' },
  { name: 'BQXBTC', symbol: 'BINANCE:BQXBTC' },
  { name: 'EOSBUSD', symbol: 'BINANCE:EOSBUSD' },
  { name: 'ETCETH', symbol: 'BINANCE:ETCETH' },
  { name: 'TRXUSDC', symbol: 'BINANCE:TRXUSDC' },
  { name: 'TRXBUSD', symbol: 'BINANCE:TRXBUSD' },
  { name: 'DCRBTC', symbol: 'BINANCE:DCRBTC' },
  { name: 'BRDBTC', symbol: 'BINANCE:BRDBTC' },
  { name: 'TRXXRP', symbol: 'BINANCE:TRXXRP' },
  { name: 'COCOSUSDT', symbol: 'BINANCE:COCOSUSDT' },
  { name: 'BTSUSDT', symbol: 'BINANCE:BTSUSDT' },
  { name: 'DNTBTC', symbol: 'BINANCE:DNTBTC' },
  { name: 'BCHBNB', symbol: 'BINANCE:BCHBNB' },
  { name: 'QSPBTC', symbol: 'BINANCE:QSPBTC' },
  { name: 'CVCUSDT', symbol: 'BINANCE:CVCUSDT' },
  { name: 'BCPTBTC', symbol: 'BINANCE:BCPTBTC' },
  { name: 'FUNUSDT', symbol: 'BINANCE:FUNUSDT' },
  { name: 'ADXBTC', symbol: 'BINANCE:ADXBTC' },
  { name: 'RCNBTC', symbol: 'BINANCE:RCNBTC' },
  { name: 'XTZBNB', symbol: 'BINANCE:XTZBNB' },
  { name: 'MATICBNB', symbol: 'BINANCE:MATICBNB' },
  { name: 'VETETH', symbol: 'BINANCE:VETETH' },
  { name: 'ERDBNB', symbol: 'BINANCE:ERDBNB' },
  { name: 'ALGOBNB', symbol: 'BINANCE:ALGOBNB' },
  { name: 'LINKBUSD', symbol: 'BINANCE:LINKBUSD' },
  { name: 'XRPBNB', symbol: 'BINANCE:XRPBNB' },
  { name: 'LRCBTC', symbol: 'BINANCE:LRCBTC' },
  { name: 'DOCKUSDT', symbol: 'BINANCE:DOCKUSDT' },
  { name: 'STORMBTC', symbol: 'BINANCE:STORMBTC' },
  { name: 'RLCUSDT', symbol: 'BINANCE:RLCUSDT' },
  { name: 'LTCBUSD', symbol: 'BINANCE:LTCBUSD' },
  { name: 'BLZBTC', symbol: 'BINANCE:BLZBTC' },
  { name: 'BNTBUSD', symbol: 'BINANCE:BNTBUSD' },
  { name: 'GRSBTC', symbol: 'BINANCE:GRSBTC' },
  { name: 'LINKUSDC', symbol: 'BINANCE:LINKUSDC' },
  { name: 'QSPETH', symbol: 'BINANCE:QSPETH' },
  { name: 'DOGEBTC', symbol: 'BINANCE:DOGEBTC' },
  { name: 'NASETH', symbol: 'BINANCE:NASETH' },
  { name: 'BCHUSDC', symbol: 'BINANCE:BCHUSDC' },
  { name: 'DENTETH', symbol: 'BINANCE:DENTETH' },
  { name: 'OAXBTC', symbol: 'BINANCE:OAXBTC' },
  { name: 'ERDBUSD', symbol: 'BINANCE:ERDBUSD' },
  { name: 'ETCBUSD', symbol: 'BINANCE:ETCBUSD' },
  { name: 'BTTBNB', symbol: 'BINANCE:BTTBNB' },
  { name: 'BNBUSDC', symbol: 'BINANCE:BNBUSDC' },
  { name: 'NAVBTC', symbol: 'BINANCE:NAVBTC' },
  { name: 'PHBTUSD', symbol: 'BINANCE:PHBTUSD' },
  { name: 'FETBNB', symbol: 'BINANCE:FETBNB' },
  { name: 'REQBTC', symbol: 'BINANCE:REQBTC' },
  { name: 'XVGETH', symbol: 'BINANCE:XVGETH' },
  { name: 'DLTBTC', symbol: 'BINANCE:DLTBTC' },
  { name: 'GVTBTC', symbol: 'BINANCE:GVTBTC' },
  { name: 'TRXPAX', symbol: 'BINANCE:TRXPAX' },
  { name: 'WPRBTC', symbol: 'BINANCE:WPRBTC' },
  { name: 'EOSUSDC', symbol: 'BINANCE:EOSUSDC' },
  { name: 'SCETH', symbol: 'BINANCE:SCETH' },
  { name: 'YOYOBTC', symbol: 'BINANCE:YOYOBTC' },
  { name: 'DREPUSDT', symbol: 'BINANCE:DREPUSDT' },
  { name: 'XMRETH', symbol: 'BINANCE:XMRETH' },
  { name: 'ADAUSDC', symbol: 'BINANCE:ADAUSDC' },
  { name: 'LENDUSDT', symbol: 'BINANCE:LENDUSDT' },
  { name: 'LINKTUSD', symbol: 'BINANCE:LINKTUSD' },
  { name: 'BANDBNB', symbol: 'BINANCE:BANDBNB' },
  { name: 'ICXETH', symbol: 'BINANCE:ICXETH' },
  { name: 'FTTUSDT', symbol: 'BINANCE:FTTUSDT' },
  { name: 'ADABUSD', symbol: 'BINANCE:ADABUSD' },
  { name: 'TRXTUSD', symbol: 'BINANCE:TRXTUSD' },
  { name: 'BTCRUB', symbol: 'BINANCE:BTCRUB' },
  { name: 'NEBLBTC', symbol: 'BINANCE:NEBLBTC' },
  { name: 'GXSBTC', symbol: 'BINANCE:GXSBTC' },
  { name: 'NEOETH', symbol: 'BINANCE:NEOETH' },
  { name: 'BTTBUSD', symbol: 'BINANCE:BTTBUSD' },
  { name: 'USDTRUB', symbol: 'BINANCE:USDTRUB' },
  { name: 'WABIBNB', symbol: 'BINANCE:WABIBNB' },
  { name: 'SNTETH', symbol: 'BINANCE:SNTETH' },
  { name: 'CTXCUSDT', symbol: 'BINANCE:CTXCUSDT' },
  { name: 'RVNBUSD', symbol: 'BINANCE:RVNBUSD' },
  { name: 'NULSBTC', symbol: 'BINANCE:NULSBTC' },
  { name: 'STORMETH', symbol: 'BINANCE:STORMETH' },
  { name: 'CDTETH', symbol: 'BINANCE:CDTETH' },
  { name: 'LTCBNB', symbol: 'BINANCE:LTCBNB' },
  { name: 'NXSBTC', symbol: 'BINANCE:NXSBTC' },
  { name: 'MITHBNB', symbol: 'BINANCE:MITHBNB' },
  { name: 'ETHEUR', symbol: 'BINANCE:ETHEUR' },
  { name: 'XRPUSDC', symbol: 'BINANCE:XRPUSDC' },
  { name: 'MCOETH', symbol: 'BINANCE:MCOETH' },
  { name: 'LENDETH', symbol: 'BINANCE:LENDETH' },
  { name: 'WTCETH', symbol: 'BINANCE:WTCETH' },
  { name: 'MANAETH', symbol: 'BINANCE:MANAETH' },
  { name: 'FTMBNB', symbol: 'BINANCE:FTMBNB' },
  { name: 'HOTBNB', symbol: 'BINANCE:HOTBNB' },
  { name: 'FUNETH', symbol: 'BINANCE:FUNETH' },
  { name: 'NKNBNB', symbol: 'BINANCE:NKNBNB' },
  { name: 'WINBNB', symbol: 'BINANCE:WINBNB' },
  { name: 'SYSBTC', symbol: 'BINANCE:SYSBTC' },
  { name: 'ASTBTC', symbol: 'BINANCE:ASTBTC' },
  { name: 'SCBNB', symbol: 'BINANCE:SCBNB' },
  { name: 'VIBETH', symbol: 'BINANCE:VIBETH' },
  { name: 'ALGOTUSD', symbol: 'BINANCE:ALGOTUSD' },
  { name: 'DASHETH', symbol: 'BINANCE:DASHETH' },
  { name: 'AEETH', symbol: 'BINANCE:AEETH' },
  { name: 'ZENBNB', symbol: 'BINANCE:ZENBNB' },
  { name: 'ADABNB', symbol: 'BINANCE:ADABNB' },
  { name: 'ZRXETH', symbol: 'BINANCE:ZRXETH' },
  { name: 'TROYBNB', symbol: 'BINANCE:TROYBNB' },
  { name: 'DATABUSD', symbol: 'BINANCE:DATABUSD' },
  { name: 'SOLBNB', symbol: 'BINANCE:SOLBNB' },
  { name: 'ZENETH', symbol: 'BINANCE:ZENETH' },
  { name: 'RVNBNB', symbol: 'BINANCE:RVNBNB' },
  { name: 'RDNBTC', symbol: 'BINANCE:RDNBTC' },
  { name: 'LSKETH', symbol: 'BINANCE:LSKETH' },
  { name: 'TNTETH', symbol: 'BINANCE:TNTETH' },
  { name: 'COTIBNB', symbol: 'BINANCE:COTIBNB' },
  { name: 'CELRBNB', symbol: 'BINANCE:CELRBNB' },
  { name: 'ADATUSD', symbol: 'BINANCE:ADATUSD' },
  { name: 'QKCETH', symbol: 'BINANCE:QKCETH' },
  { name: 'ONTETH', symbol: 'BINANCE:ONTETH' },
  { name: 'MBLBNB', symbol: 'BINANCE:MBLBNB' },
  { name: 'AIONETH', symbol: 'BINANCE:AIONETH' },
  { name: 'XLMETH', symbol: 'BINANCE:XLMETH' },
  { name: 'MATICBUSD', symbol: 'BINANCE:MATICBUSD' },
  { name: 'XTZBUSD', symbol: 'BINANCE:XTZBUSD' },
  { name: 'NANOETH', symbol: 'BINANCE:NANOETH' },
  { name: 'ONEBNB', symbol: 'BINANCE:ONEBNB' },
  { name: 'ENJBUSD', symbol: 'BINANCE:ENJBUSD' },
  { name: 'IOSTETH', symbol: 'BINANCE:IOSTETH' },
  { name: 'BCDETH', symbol: 'BINANCE:BCDETH' },
  { name: 'LTCPAX', symbol: 'BINANCE:LTCPAX' },
  { name: 'STEEMETH', symbol: 'BINANCE:STEEMETH' },
  { name: 'ICXBNB', symbol: 'BINANCE:ICXBNB' },
  { name: 'XMRBNB', symbol: 'BINANCE:XMRBNB' },
  { name: 'BTTTUSD', symbol: 'BINANCE:BTTTUSD' },
  { name: 'ETHTRY', symbol: 'BINANCE:ETHTRY' },
  { name: 'ICXBUSD', symbol: 'BINANCE:ICXBUSD' },
  { name: 'ZECETH', symbol: 'BINANCE:ZECETH' },
  { name: 'VETBUSD', symbol: 'BINANCE:VETBUSD' },
  { name: 'IOTAETH', symbol: 'BINANCE:IOTAETH' },
  { name: 'PERLBNB', symbol: 'BINANCE:PERLBNB' },
  { name: 'MFTUSDT', symbol: 'BINANCE:MFTUSDT' },
  { name: 'XMRBUSD', symbol: 'BINANCE:XMRBUSD' },
  { name: 'BTTUSDC', symbol: 'BINANCE:BTTUSDC' },
  { name: 'QLCETH', symbol: 'BINANCE:QLCETH' },
  { name: 'NEBLETH', symbol: 'BINANCE:NEBLETH' },
  { name: 'KEYETH', symbol: 'BINANCE:KEYETH' },
  { name: 'CVCETH', symbol: 'BINANCE:CVCETH' },
  { name: 'ENGETH', symbol: 'BINANCE:ENGETH' },
  { name: 'DATAETH', symbol: 'BINANCE:DATAETH' },
  { name: 'BATETH', symbol: 'BINANCE:BATETH' },
  { name: 'HBARBNB', symbol: 'BINANCE:HBARBNB' },
  { name: 'CHRBNB', symbol: 'BINANCE:CHRBNB' },
  { name: 'WRXBNB', symbol: 'BINANCE:WRXBNB' },
  { name: 'STXBNB', symbol: 'BINANCE:STXBNB' },
  { name: 'ARNETH', symbol: 'BINANCE:ARNETH' },
  { name: 'VETBNB', symbol: 'BINANCE:VETBNB' },
  { name: 'LTCTUSD', symbol: 'BINANCE:LTCTUSD' },
  { name: 'ONTBUSD', symbol: 'BINANCE:ONTBUSD' },
  { name: 'ALGOBUSD', symbol: 'BINANCE:ALGOBUSD' },
  { name: 'WINUSDC', symbol: 'BINANCE:WINUSDC' },
  { name: 'NEOBUSD', symbol: 'BINANCE:NEOBUSD' },
  { name: 'OSTETH', symbol: 'BINANCE:OSTETH' },
  { name: 'KMDETH', symbol: 'BINANCE:KMDETH' },
  { name: 'QTUMETH', symbol: 'BINANCE:QTUMETH' },
  { name: 'LOOMETH', symbol: 'BINANCE:LOOMETH' },
  { name: 'BRDETH', symbol: 'BINANCE:BRDETH' },
  { name: 'STRATETH', symbol: 'BINANCE:STRATETH' },
  { name: 'WANETH', symbol: 'BINANCE:WANETH' },
  { name: 'ONTBNB', symbol: 'BINANCE:ONTBNB' },
  { name: 'HIVEBNB', symbol: 'BINANCE:HIVEBNB' },
  { name: 'ANKRBNB', symbol: 'BINANCE:ANKRBNB' },
  { name: 'NANOBUSD', symbol: 'BINANCE:NANOBUSD' },
  { name: 'KAVABNB', symbol: 'BINANCE:KAVABNB' },
  { name: 'DOCKETH', symbol: 'BINANCE:DOCKETH' },
  { name: 'NULSETH', symbol: 'BINANCE:NULSETH' },
  { name: 'BNBTUSD', symbol: 'BINANCE:BNBTUSD' },
  { name: 'STEEMBNB', symbol: 'BINANCE:STEEMBNB' },
  { name: 'RLCETH', symbol: 'BINANCE:RLCETH' },
  { name: 'CMTETH', symbol: 'BINANCE:CMTETH' },
  { name: 'ENJBNB', symbol: 'BINANCE:ENJBNB' },
  { name: 'STORJETH', symbol: 'BINANCE:STORJETH' },
  { name: 'EDOETH', symbol: 'BINANCE:EDOETH' },
  { name: 'POWRETH', symbol: 'BINANCE:POWRETH' },
  { name: 'EVXETH', symbol: 'BINANCE:EVXETH' },
  { name: 'DASHBUSD', symbol: 'BINANCE:DASHBUSD' },
  { name: 'WAVESETH', symbol: 'BINANCE:WAVESETH' },
  { name: 'GTOETH', symbol: 'BINANCE:GTOETH' },
  { name: 'ZECBUSD', symbol: 'BINANCE:ZECBUSD' },
  { name: 'XLMBUSD', symbol: 'BINANCE:XLMBUSD' },
  { name: 'DASHBNB', symbol: 'BINANCE:DASHBNB' },
  { name: 'BATBNB', symbol: 'BINANCE:BATBNB' },
  { name: 'GNTETH', symbol: 'BINANCE:GNTETH' },
  { name: 'AIONBNB', symbol: 'BINANCE:AIONBNB' },
  { name: 'BATUSDC', symbol: 'BINANCE:BATUSDC' },
  { name: 'MTLETH', symbol: 'BINANCE:MTLETH' },
  { name: 'NEBLBNB', symbol: 'BINANCE:NEBLBNB' },
  { name: 'ZECUSDC', symbol: 'BINANCE:ZECUSDC' },
  { name: 'LRCETH', symbol: 'BINANCE:LRCETH' },
  { name: 'OGNBNB', symbol: 'BINANCE:OGNBNB' },
  { name: 'BNBTRY', symbol: 'BINANCE:BNBTRY' },
  { name: 'PPTETH', symbol: 'BINANCE:PPTETH' },
  { name: 'IOTABNB', symbol: 'BINANCE:IOTABNB' },
  { name: 'WANBNB', symbol: 'BINANCE:WANBNB' },
  { name: 'PIVXETH', symbol: 'BINANCE:PIVXETH' },
  { name: 'BTTPAX', symbol: 'BINANCE:BTTPAX' },
  { name: 'XEMETH', symbol: 'BINANCE:XEMETH' },
  { name: 'XRPPAX', symbol: 'BINANCE:XRPPAX' },
  { name: 'QTUMBUSD', symbol: 'BINANCE:QTUMBUSD' },
  { name: 'NCASHETH', symbol: 'BINANCE:NCASHETH' },
  { name: 'NEOBNB', symbol: 'BINANCE:NEOBNB' },
  { name: 'REPETH', symbol: 'BINANCE:REPETH' },
  { name: 'IOSTBNB', symbol: 'BINANCE:IOSTBNB' },
  { name: 'ELFETH', symbol: 'BINANCE:ELFETH' },
  { name: 'ATOMBNB', symbol: 'BINANCE:ATOMBNB' },
  { name: 'XRPTUSD', symbol: 'BINANCE:XRPTUSD' },
  { name: 'CTSIBNB', symbol: 'BINANCE:CTSIBNB' },
  { name: 'BNBEUR', symbol: 'BINANCE:BNBEUR' },
  { name: 'NEOTUSD', symbol: 'BINANCE:NEOTUSD' },
  { name: 'AGIETH', symbol: 'BINANCE:AGIETH' },
  { name: 'WAVESBNB', symbol: 'BINANCE:WAVESBNB' },
  { name: 'EOSTUSD', symbol: 'BINANCE:EOSTUSD' },
  { name: 'ZILBUSD', symbol: 'BINANCE:ZILBUSD' },
  { name: 'BRDBNB', symbol: 'BINANCE:BRDBNB' },
  { name: 'BUSDTRY', symbol: 'BINANCE:BUSDTRY' },
  { name: 'TOMOBUSD', symbol: 'BINANCE:TOMOBUSD' },
  { name: 'BEAMBNB', symbol: 'BINANCE:BEAMBNB' },
  { name: 'ALGOPAX', symbol: 'BINANCE:ALGOPAX' },
  { name: 'COCOSBNB', symbol: 'BINANCE:COCOSBNB' },
  { name: 'BQXETH', symbol: 'BINANCE:BQXETH' },
  { name: 'AIONBUSD', symbol: 'BINANCE:AIONBUSD' },
  { name: 'ETHRUB', symbol: 'BINANCE:ETHRUB' },
  { name: 'ZECTUSD', symbol: 'BINANCE:ZECTUSD' },
  { name: 'HBARBUSD', symbol: 'BINANCE:HBARBUSD' },
  { name: 'WAVESUSDC', symbol: 'BINANCE:WAVESUSDC' },
  { name: 'BTCZAR', symbol: 'BINANCE:BTCZAR' },
  { name: 'USDCPAX', symbol: 'BINANCE:USDCPAX' },
  { name: 'XRPTRY', symbol: 'BINANCE:XRPTRY' },
  { name: 'ZRXBNB', symbol: 'BINANCE:ZRXBNB' },
  { name: 'XLMBNB', symbol: 'BINANCE:XLMBNB' },
  { name: 'WTCBNB', symbol: 'BINANCE:WTCBNB' },
  { name: 'GVTETH', symbol: 'BINANCE:GVTETH' },
  { name: 'BATBUSD', symbol: 'BINANCE:BATBUSD' },
  { name: 'TOMOBNB', symbol: 'BINANCE:TOMOBNB' },
  { name: 'LTOBNB', symbol: 'BINANCE:LTOBNB' },
  { name: 'EOSPAX', symbol: 'BINANCE:EOSPAX' },
  { name: 'XZCETH', symbol: 'BINANCE:XZCETH' },
  { name: 'BLZETH', symbol: 'BINANCE:BLZETH' },
  { name: 'XZCBNB', symbol: 'BINANCE:XZCBNB' },
  { name: 'STRATBUSD', symbol: 'BINANCE:STRATBUSD' },
  { name: 'ATOMBUSD', symbol: 'BINANCE:ATOMBUSD' },
  { name: 'STPTBNB', symbol: 'BINANCE:STPTBNB' },
  { name: 'RLCBNB', symbol: 'BINANCE:RLCBNB' },
  { name: 'HCETH', symbol: 'BINANCE:HCETH' },
  { name: 'BNBPAX', symbol: 'BINANCE:BNBPAX' },
  { name: 'ARPABNB', symbol: 'BINANCE:ARPABNB' },
  { name: 'NEOUSDC', symbol: 'BINANCE:NEOUSDC' },
  { name: 'CHZBNB', symbol: 'BINANCE:CHZBNB' },
  { name: 'BTSBUSD', symbol: 'BINANCE:BTSBUSD' },
  { name: 'USDTZAR', symbol: 'BINANCE:USDTZAR' },
  { name: 'BTCIDRT', symbol: 'BINANCE:BTCIDRT' },
  { name: 'BLZBNB', symbol: 'BINANCE:BLZBNB' },
  { name: 'MFTETH', symbol: 'BINANCE:MFTETH' },
  { name: 'GRSETH', symbol: 'BINANCE:GRSETH' },
  { name: 'BNBNGN', symbol: 'BINANCE:BNBNGN' },
  { name: 'GXSETH', symbol: 'BINANCE:GXSETH' },
  { name: 'BUSDRUB', symbol: 'BINANCE:BUSDRUB' },
  { name: 'ATOMUSDC', symbol: 'BINANCE:ATOMUSDC' },
  { name: 'FTTBNB', symbol: 'BINANCE:FTTBNB' },
  { name: 'ADXETH', symbol: 'BINANCE:ADXETH' },
  { name: 'ZECBNB', symbol: 'BINANCE:ZECBNB' },
  { name: 'WAVESBUSD', symbol: 'BINANCE:WAVESBUSD' },
  { name: 'ONEUSDC', symbol: 'BINANCE:ONEUSDC' },
  { name: 'RENBNB', symbol: 'BINANCE:RENBNB' },
  { name: 'ETHZAR', symbol: 'BINANCE:ETHZAR' },
  { name: 'XRPEUR', symbol: 'BINANCE:XRPEUR' },
  { name: 'WRXBUSD', symbol: 'BINANCE:WRXBUSD' },
  { name: 'USDCTUSD', symbol: 'BINANCE:USDCTUSD' },
  { name: 'XRPRUB', symbol: 'BINANCE:XRPRUB' },
  { name: 'CTSIBUSD', symbol: 'BINANCE:CTSIBUSD' },
  { name: 'MFTBNB', symbol: 'BINANCE:MFTBNB' },
  { name: 'USDTIDRT', symbol: 'BINANCE:USDTIDRT' },
  { name: 'RDNETH', symbol: 'BINANCE:RDNETH' },
  { name: 'GTOBNB', symbol: 'BINANCE:GTOBNB' },
  { name: 'BNBRUB', symbol: 'BINANCE:BNBRUB' },
  { name: 'BUSDIDRT', symbol: 'BINANCE:BUSDIDRT' },
  { name: 'USDSUSDT', symbol: 'BINANCE:USDSUSDT' },
  { name: 'PAXTUSD', symbol: 'BINANCE:PAXTUSD' },
  { name: 'TOMOUSDC', symbol: 'BINANCE:TOMOUSDC' },
  { name: 'BNBIDRT', symbol: 'BINANCE:BNBIDRT' },
  { name: 'BUSDZAR', symbol: 'BINANCE:BUSDZAR' },
  { name: 'BNBZAR', symbol: 'BINANCE:BNBZAR' },
  { name: 'BGBPUSDC', symbol: 'BINANCE:BGBPUSDC' },
];

export default pairs;
