import React from 'react';
import { Table, TableContainer } from '@mui/material';
import NewGlobalBids from './NewGlobalBids';
import TopTraders from './TopTraders';
import UserActivity from './UserActivity';
import TrendingCollection from './TrendingCollection';
import OptionInput from './OptionInput';

const TableWidget = ({ id, data, setData, options, editMode }) => {
  return (
    <>
      <div className={!editMode ? 'flex space-x-2 my-2 px-1' : 'flex space-x-2 my-2 px-3'}>
        {options.map((option, index) => {
          return (
            <OptionInput
              key={index}
              id={id}
              option={option}
              data={data}
              setData={setData}
              disabled={!editMode}
            />
          );
        })}
      </div>
      <TableContainer sx={{ maxHeight: '100%' }} className='scrollbar-custom overflow-x-auto'>
        <Table stickyHeader aria-label='sticky table'>
          {data?.tableName === 'top-users' && <TopTraders data={data} options={options} />}
          {data?.tableName === 'new-global-bids' && <NewGlobalBids data={data} options={options} />}
          {data?.tableName === 'user-activity' && <UserActivity data={data} options={options} />}
          {data?.tableName === 'trending-collection' && (
            <TrendingCollection data={data} options={options} />
          )}
        </Table>
      </TableContainer>
    </>
  );
};

export default TableWidget;
