import { shortenAddress } from '../../../helpers/address';
import { numFormatter } from '../../../lib/numberFormatter';
import { getItemPath } from '../../../helpers/path';
import { getCdnUrl } from '../../../lib/cdnUrl';
import useGetActivities from '../../../hooks/useGetActivities';
import { useRouter } from 'next/router';
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { ChainIcon } from '../../Icon';
import { getTokenName } from '../../../helpers/getChainName';
import Link from 'next/link';
import { ImageFixedAO } from '../../Image_fixed_aspect_ratio';
import { LoadingSpinner } from '../../loadingSpinner';
import { constants } from '../../../helpers/constants';

const NewGlobalBids = ({ options, data }) => {
  const {
    query: { user },
  } = useRouter();
  const { data: swr, isLoading } = useGetActivities(
    'updatedAt:desc',
    100,
    1,
    'bid',
    data?.users,
    data?.collections || '',
  );
  const columns = [
    { id: 'items', label: 'Items', minWidth: 50, colspan: 2 },
    { id: 'price', label: 'Price', minWidth: 50 },
    { id: 'from', label: 'From', minWidth: 50 },
    { id: 'to', label: 'to', minWidth: 50 },
    { id: 'date', label: 'Date', minWidth: 50 },
  ];

  return (
    <>
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={column.id}
              align={column.align}
              style={{ minWidth: column.minWidth }}
              rowSpan={column.colspan}
              classes={{
                root: 'dark:bg-jacarta-600 bg-light-base dark:text-jacarta-100 text-jacarta-700',
              }}
            >
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {isLoading && (
          <TableRow>
            <TableCell align='center' rowSpan={5} colSpan={5}>
              <LoadingSpinner className='flex justify-center' />
            </TableCell>
          </TableRow>
        )}
        {!isLoading &&
          swr?.results?.map((item, index) => {
            let {
              collectionAddress,
              itemId,
              createdAt,
              eventType,
              price,
              from,
              to,
              chain,
              metadata,
              item: aitem,
              collections: collectionInfo,
            } = item;
            if (!chain) chain = '0x38';
            metadata = JSON.parse(aitem.metadata);
            let image;
            if (collectionInfo?.isDNFT) {
              image = collectionInfo.thumbnailAPI + itemId;
            } else {
              image = aitem?.imageFtpPath
                ? constants.cdn.path +
                  aitem.imageFtpPath +
                  constants.cdn.detail +
                  constants.cdn.version
                : metadata?.image;
            }
            if (collectionInfo.address === to && eventType === 'bid') {
              image = getCdnUrl(collectionInfo.profilePhotoPath);
            }
            image = image.replace('w-540', 'w-270');

            return (
              <TableRow key={index}>
                <TableCell
                  classes={{
                    root: 'flex items-center space-x-3 dark:bg-jacarta-600 dark:text-jacarta-100 text-jacarta-700',
                  }}
                >
                  <figure className='w-12 shrink-0'>
                    <ImageFixedAO image={image} alt={collectionAddress} bottomRounded />
                  </figure>
                  <Link href={getItemPath(chain, collectionAddress, itemId)}>
                    <a>
                      <span className='font-display text-accent hover:text-accent-light text-sm font-semibold dark:text-white'>
                        {shortenAddress(collectionAddress)}
                      </span>
                    </a>
                  </Link>
                </TableCell>
                <TableCell
                  classes={{
                    root: 'dark:bg-jacarta-600 dark:text-jacarta-100 text-jacarta-700',
                  }}
                >
                  <span className='flex items-center space-x-1 font-display text-jacarta-700 text-sm font-semibold dark:text-white'>
                    <ChainIcon name={chain} tooltip={getTokenName(chain)} sideGap={true} />
                    <span className='mt-1'>{price ? numFormatter(price, 4) : '-'}</span>
                  </span>
                </TableCell>
                <TableCell
                  classes={{
                    root: 'dark:bg-jacarta-600 dark:text-jacarta-100 text-jacarta-700',
                  }}
                >
                  <span className='font-display text-jacarta-700 text-sm font-semibold dark:text-white'>
                    {from && shortenAddress(from)}
                  </span>
                </TableCell>
                <TableCell
                  classes={{
                    root: 'dark:bg-jacarta-600 dark:text-jacarta-100 text-jacarta-700',
                  }}
                >
                  <span className='font-display text-jacarta-700 text-sm font-semibold dark:text-white'>
                    {to && shortenAddress(to)}
                  </span>
                </TableCell>
                <TableCell
                  classes={{
                    root: 'dark:bg-jacarta-600 dark:text-jacarta-100 text-jacarta-700',
                  }}
                >
                  <span className='font-display text-jacarta-700 text-sm font-semibold dark:text-white'>
                    {new Date(createdAt).toLocaleDateString('en-US')}
                  </span>
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </>
  );
};
export default NewGlobalBids;
