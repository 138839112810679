import React from 'react';

const FearAndGreed = () => {
  return (
    <div className='overflow-y-auto scrollbar-custom aspect-4/3'>
      <img
        src='https://alternative.me/crypto/fear-and-greed-index.png'
        alt=''
        className='w-[80%] mx-auto'
      />
    </div>
  );
};

export default FearAndGreed;
