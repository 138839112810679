import { Timeline } from 'react-ts-tradingview-widgets';
import { useTheme } from 'next-themes';

const TimelineWidget = () => {
  const { theme, setTheme } = useTheme();

  return (
    <div>
      <Timeline colorTheme={theme} feedMode='market' market='crypto' height={400} width='100%' />
    </div>
  );
};

export default TimelineWidget;
