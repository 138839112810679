import { constants } from '../helpers/constants';
import axios from '../lib/axios';
import useAdaptor from '../lib/swr';
import { useContext } from 'react';
import { DataRefreshContext } from '../components/refreshContext';

export function useGetWidget() {
  const { refreshDate } = useContext(DataRefreshContext);
  const config = {
    url: `${constants.api.url_new}/widget`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: constants.httpMethods.get,
    data: { refreshDate },
  };

  const fetcher = () =>
    axios(config).then((response) => {
      const data = response.data.data;
      return data.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    });

  return useAdaptor(axios.getUri(config), fetcher);
}

export function useGetWidgetFilter(query, search) {
  const { refreshDate } = useContext(DataRefreshContext);
  const config = {
    url: `${constants.api.url_new}/widget/filter`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: constants.httpMethods.get,
    params: {
      query: query,
      search,
      refreshDate,
    },
  };

  const fetcher = () =>
    axios(config).then((response) => {
      return response.data.data;
    });

  return useAdaptor(axios.getUri(config), query ? fetcher : null);
}
