import React from 'react';

const BubbleMaps = (props) => {
  return (
    <iframe
      width={'100%'}
      height={'100%'}
      src='https://app.bubblemaps.io/bsc/token/0xe32ae22ec60e21980247b4bdaa16e9aea265f919'
      frameBorder='0'
    />
  );
};

export default BubbleMaps;
