import { CryptoCurrencyMarket } from 'react-ts-tradingview-widgets';
import { useTheme } from 'next-themes';

const CryptocurrencyMarketWidget = () => {
  const { theme, setTheme } = useTheme();

  return (
    <div>
      <CryptoCurrencyMarket colorTheme={theme} width='100%' height={400} />
    </div>
  );
};

export default CryptocurrencyMarketWidget;
